<template>
  <div class="navdesktop">
    <div class="navdesktop__wrapper c__box">
      <div class="navdesktop__left">

        <TheLogoBlack class="navdesktop__thelogo" />

      </div>
      <!-- <div class="navdesktop__middle">
     
      </div> -->
      <div class="navdesktop__middle">
        <div class="navdesktop__content">
        <NavBtn :action="'home'" :icon="'home'">
          <slot>
            home
          </slot>
        </NavBtn>
        <NavBtn :action="'about'" :icon="'info'">
          <slot>
            About
          </slot>
        </NavBtn>
        <NavBtn :action="'kontakt'" :icon="'mail'">
          <slot>
            kontakt
          </slot>
        </NavBtn>
        <NavBtn :action="'markenerfahrung'" :icon="'favorite'">
          <slot>
            Brands
          </slot>
        </NavBtn>
        <NavBtn :action="'leistungen'" :icon="'devices_other'">
          <slot>
            leistungen
          </slot>
        </NavBtn>
        <NavBtn :action="'firmen'" :icon="'groups'">
          <slot>
            firmen
          </slot>
        </NavBtn>
        <NavBtn :action="'adserver'" :icon="'diversity_2'">
          <slot>
            adserver
          </slot>
        </NavBtn>
        <NavBtn :action="'preise'" :icon="'precision_manufacturing'">
          <slot>
            preise
          </slot>
        </NavBtn>
        <NavBtn :action="'rezension'" :icon="'group_add'">
          <slot>
            Rezension
          </slot>
        </NavBtn>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import NavBtn from "./NavBtn";
// import TheLogoSmall from "@/components/ui/logo/TheLogoSmall.vue";
import { computed } from "@vue/runtime-core";

import TheLogoBlack from '../../ui/logo/TheLogoBlack.vue';

import { useStore } from "vuex";
export default {
  components: { TheLogoBlack, NavBtn },

  setup() {
    const store = useStore();
    const publicPath = process.env.BASE_URL;

    const isPeople = computed(() => {
      return store.state.isPeopleOpen;
    });
    const isHelp = computed(() => {
      return store.state.isHelpDeskOpen;
    });
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    const messageText = computed(() => {
      if (store.state.isMenuOpen) {
        return "menü";
      } else {
        return store.state.isActiveRouteName;
      }
    });
    //  const getPath = function(path) {
    // let newP = path.split(".jpg");
    // if (newP.length >= 2) {
    //   return publicPath + path;
    // }}

    function onClick() {
      store.commit("setMenuState", false);
    }

    function prefixPath(el) {
      return publicPath + el;
    }

    function toggleElement(e, el) {
      console.log("e", e, el);
    }
    return {
      isPeople,
      isHelp,
      isMenuOpen,
      toggleElement,
      prefixPath,
      store,
      messageText,
      onClick
    };
  },
};
</script>
