<template>
  <div class="overlay">
    <div
      ref="overlayBg"
      class="overlay__bg"
      @click.prevent="closeOverlay"
    ></div>

    <div
      id="overlay__container"
      class="overlay__container"
      @click.prevent="closeOverlay"
    >
      <div class="overlay__wrapper" ref="overlayWrapper">
        <Notification />
      </div>
    </div>
  </div>
</template>

<script>
import Notification from "./Notification.vue";
import overlayHandling from "@/utilities/composition/overlay/overlayHandling";
import { gsap } from "gsap";

// ASYNC
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: { Notification },
  setup() {
    const store = useStore();

    const { closeOverlay } = overlayHandling();

    // Async component with options

    const generateId = computed(() => {
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    });

    const overlayTS = ref(null);
    const overlayComponent = ref(null);
    const overlayBg = ref(null);
    const overlayWrapper = ref(null);

    const randomId = ref(generateId);

    onMounted(() => {
      let OLW = overlayWrapper.value;
      let OLBG = overlayBg.value;

      let del = 3;

      gsap.set(OLBG, { opacity: 0, force3D: true });
      gsap.to(OLBG, { duration: 1, opacity: 1, delay: del +1 });

      gsap.set(OLW, { y: "20vh", opacity: 0, rotation: 0.02, force3D: true });
      gsap.to(OLW, {
        duration: 0.5,
        y: 0,
        ease: "power2.out",
        opacity: 1,
        rotation: 0,
        delay: del+ 1.4,
      });
    });

    return {
      overlayBg,
      overlayComponent,
      overlayWrapper,
      randomId,
      closeOverlay,
      overlayTS,
      store,
    };
  },
};
</script>

<style lang="scss">

</style>
