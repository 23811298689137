<template>
  <div id="ocontroller">
    <div id="ocontroller__wrapper">
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {  },
  setup() {
    const store = useStore();

    function openAnimation() {
      let ease1 = "power4.out";

      gsap.from("#ocontroller", {
        force3D: true,
        duration: 0.4,
        ease: ease1,
        y: -200,
        opacity: 0,
        rotation: 0.02,
      });
    }
    onMounted(() => {
      openAnimation();
    });
    return { store };
  },
};
</script>

<style lang="scss">

</style>
