<template>
  <button @click.prevent="closeOverlay" class="closeoverlay">
    <div class="abco__icon">
      <span class="s_1"></span>
      <span class="s_2"></span>
    </div>
  </button>
</template>

<script>
import overlayHandling from "@/utilities/composition/overlay/overlayHandling";
export default {
  setup() {
    const { closeOverlay } = overlayHandling();

    return {
      closeOverlay,
    };
  },
};
</script>

