const service = [
  {
    name: "HTML5 Banner",
    text:"Lorem"
  },
  {
    name: "Display Kampagnen",
    text:"Lorem"
  },
  {
    name: "Mobile Kampagnen",
    text:"Lorem"
  },
  {
    name: "Rich Media Kampagnen",
    text:"Lorem"
  },
  {
    name: "Dynamic Sitebars",
    text:"Lorem"
  },
  {
    name: "Responsive Ads",
    text:"Lorem"
  },
  {
    name: "Sonderwerbemittel",
    text:"Lorem"
  },
  {
    name: "Dynamic Ads",
    text:"Lorem"
  },
  {
    name: "WEBGL Ads",
    text:"Lorem"
  },
  {
    name: "VIDEO Ads",
    text:"Lorem"
  },
  {
    name: "Kreation",
    text:"Lorem"
  },
  {
    name: "Digitale Verlängerungen",
    text:"Lorem"
  },
];
export default service;
