<template>
  <div
    class="navbtn"
    :class="{ 'isActive': isSpecial || onMouseEnter}"
    @click="onClick"
    @mouseenter="onMouseEnter = true"
    @mouseleave="onMouseEnter = false"
  >
    <div class="navbtn__text">
      <div v-if="icon" class="material-icons-round navbtn__icon" translate="no">
        {{icon}}
        </div>
        <div class="navbtn__content">
          <slot></slot>
        </div>
    
      <div class="navbtn__marker"></div>
    </div>
  </div>
</template>

<script>
// import Lenis from '@studio-freight/lenis'
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: { dataname: String,action:String, icon:String },
  setup(props) {
    const store = useStore();
    const onMouseEnter = ref(false);
    const publicPath = process.env.BASE_URL;

    const isBtn = ref(props.dataname);

    function onClick() {
      store.commit("setMenuState", false);

      if (props.action) {
        let  section = document.getElementById(props.action)
      let sectionTop = section.offsetTop;
      window.scrollTo({
                top: sectionTop,
                behavior: "smooth"
              });

      }

     
    }

    const isSpecial = computed(() => {
      return store.state.isActiveRoute == publicPath + isBtn.value;
    });

    return { onMouseEnter, isSpecial, store, isBtn, onClick };
  },
};
</script>

