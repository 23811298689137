<template>
  <nav class="nav" :class="store.state.isMainColor">
    <TheNavDesktop v-if="getState"/>
    <TheNavDesktop v-else/>
  </nav>
</template>

<script>
import TheNavDesktop from "./TheNavDesktop";
// import TheNavMobile from "./TheNavMobile";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    TheNavDesktop,
  },

  setup() {
    const store = useStore();
    const getState = computed(() => {
      if (
        store.state.isScreenState == "medium" ||
        store.state.isScreenState == "small"
      ) {
        return true;
      }
    });

    return {
      store,
      getState,
    };
  },
};
</script>

<style lang="scss">
nav {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
