<template>
  <div class="thelogo" ref="LOGO">
    <div class="thelogo__wrapper">
      <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8_14)">
<path d="M42.9552 17.5733L21.933 53.9889L32.2351 71.8458H53.6753L63.9774 53.9889L42.9552 17.5733Z" fill="black"/>
<path d="M75.25 0H10.75C4.80764 0 0 4.80764 0 10.75V75.25C0 81.1924 4.80764 86 10.75 86H75.25C81.1924 86 86 81.1924 86 75.25V10.75C86 4.80764 81.1924 0 75.25 0ZM30.2642 75.25L17.9913 53.9889L42.9552 10.75L67.9191 53.9889L55.6462 75.25H30.2642ZM67.9191 71.816C65.8885 71.816 64.2611 70.1736 64.2611 68.158C64.2611 66.1274 65.9035 64.5 67.9191 64.5C69.9497 64.5 71.5771 66.1424 71.5771 68.158C71.5771 70.1736 69.9347 71.816 67.9191 71.816Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8_14">
<rect width="86" height="86" fill="white"/>
</clipPath>
</defs>
</svg>
    </div>
  </div>
</template>

<!-- <script>
import getPathFunction from "@/utilities/composition/getPathFunction";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { onMounted, ref } from 'vue';
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

export default {
  props:{animation:Boolean},
  setup(props) {
    const { getPath } = getPathFunction();

    const LOGO = ref(null);

    function startAnimating(){
      let bg_1= LOGO.value.querySelector(".thelogo__bg--1")
      let bg_2 = LOGO.value.querySelector(".thelogo__bg--2")
     
     let x_1= LOGO.value.querySelector(".thelogo__outline--2")
     let x_2= LOGO.value.querySelector(".thelogo__outline--3")
     let test_4= LOGO.value.querySelector(".thelogo__outline--4")
      let test_5 = LOGO.value.querySelector(".thelogo__outline--5")

      
     let g_1= LOGO.value.querySelector(".thelogo__g--1");
     let p_k= LOGO.value.querySelector(".thelogo__path--k");
     let p_f= LOGO.value.querySelector(".thelogo__path--f");
      let p_z = LOGO.value.querySelector(".thelogo__path--z");
      let p_w = LOGO.value.querySelector(".thelogo__path--w");

      gsap.from(bg_1, { duration: 1,opacity:0, scale: 0, transformOrigin: "50% 50%" , ease:"power4.inOut", delay:0});
      gsap.from(bg_2, { duration: 1, scale: 0, transformOrigin: "50% 50%" , ease:"power4.inOut", delay:0.2});

      gsap.from(test_4, {
        delay: 0.8,
        duration: 2, drawSVG: "0 0", 
        ease:"power4.inOut"
      })

      gsap.to( test_5, {opacity:1, delay:1.6})
          gsap.to( test_4, {opacity:0, delay:1.6})

      var del_2 = 1.4;
      gsap.from( x_1, { duration:0.5, scale:0, opacity:0,transformOrigin:"0% 100%", delay:del_2+ 0 })
      gsap.from(x_2, { duration: 0.5,  scale: 0, opacity: 0, transformOrigin: "100% 100%", delay: del_2+0.2 })

      let del = 1.5;

      let easy= "power4.inOut";

      gsap.from(p_k, { duration: 1, rotation: 0, scale: 0,ease: easy, transformOrigin: "50% 50%", delay:del+ 0 })
      gsap.from(p_f, { duration: 1, rotation: 0, scale: 0, ease: easy,transformOrigin: "50% 50%" , delay:del+ 0.1})
      gsap.from(p_z, { duration: 1, rotation: 0, scale: 0,ease: easy, transformOrigin: "50% 50%", delay:del+ 0.2 })
      gsap.from(p_w, { duration: 2, rotation: 0, scale: 0, ease: "elastic.out(1,0.3)",transformOrigin: "50% 50%", delay:del+ 0.3 })


      gsap.from( g_1, { duration:1,  opacity:0,transformOrigin:"50% 50%", delay:del+1 })

    }

    onMounted(() => {

      console.log("props.animation", props.animation);
        if (props.animation) {
          startAnimating();
        }

    
// 
    })

    return { getPath,LOGO,startAnimating };
  },
};
</script> -->

<style lang="scss" scoped>
.thelogo__outline--1 {
  fill: white;
}

.thelogo__outline--4 {
  fill: none;
  stroke-width: 1;
  stroke: white;
}

.thelogo__outline--5 {
  fill: white;
  opacity: 0;
}

.thelogo__svg {
  width: 100%;
  height: 100%;
}

.thelogo_png {
  width: 100%;
  height: auto;
}

.thelogo__color {
  fill: white;
}

.thelogo {

  &.isblack {
    .thelogo__color {
      fill: black;
    }
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.st2 {
  fill: #ffffff;
}


.thelogo__wrapper{

overflow: hidden;
border-radius: 20px;
width:100%;
height: 100%;
}
#the_logo{
width:100%;
height: 100%;
}

</style>
