<template>
  <div class="notifications">
    <div class="introb1__close">
      <AppButtonCloseOverlay />
    </div>
    <div class="introb1__wrapper">
      <div class="introb1__content introb1__content">
        <h1 class="introb1__h1 h1 introb1__h1--nomarginbottom">
          Eröffnungsfeier 
          <br>
          Mit Tag der Offenen Tür
          <br>
          Am Sa. 01.04.2023
          <br>
          Ab 10 Uhr
        </h1>
        <div class="introb1__boxbutton">
          <AppButton class="introb1__btn">Zurück zur Seite</AppButton>
        </div>
      </div>
    </div>
    <div class="introb1__logo">
      <TheLogo />
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonCloseOverlay from "../../ui/buttons/AppButtonCloseOverlay.vue";
export default {
  components: { AppButtonCloseOverlay, TheLogo, AppButton },
};
</script>

<style lang="scss">

</style>
